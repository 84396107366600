<template>
  <div>
    <table
      class="evaluation-criterias-table"
      v-if="institution && institution.show_evaluation_criteria_macro"
    >
      <thead class="evaluation-criterias-header">
        <th class="th-custom-first">
          {{
            $getVisibleNames(
              "teaching.evaluationcriteriamacro",
              true,
              "Criterio de Evaluación Macro"
            )
          }}
        </th>
        <th class="th-custom-second">Menciones</th>
      </thead>
      <tbody>
        <tr
          class="ec-macro-tr"
          v-for="ec_macro in evaluationCriteriaMacroList"
          :key="ec_macro.id"
        >
          <td
            class="ec-macro-td"
            :class="{
              'criteria-without-mention': !appliedCriteriaMacroAmount(
                ec_macro.id
              ),
            }"
          >
            <span class="evaluation-criterias-macros-order">
              {{ ec_macro.order + ". " }}
            </span>
            <SentenceContainer
              class="text-class"
              :Sentence="ec_macro"
              :boundedTextContainer="true"
              :Large="140"
            ></SentenceContainer>
          </td>
          <td class="ec-micro-td text-center">
            <b-badge
              class="custom-badge"
              :class="{ 'bg-red': !appliedCriteriaMacroAmount(ec_macro.id) }"
              >{{ appliedCriteriaMacroAmount(ec_macro.id) }}</b-badge
            >
          </td>
        </tr>
      </tbody>
    </table>
    <template
      v-if="
        !evaluationCriteriaMacroList.length &&
        institution &&
        institution.show_evaluation_criteria_macro
      "
    >
      <div class="text-center mb-3">
        <b-icon class="mr-1" icon="exclamation-circle"></b-icon> No existen
        {{
          $getVisibleNames(
            "teaching.evaluationcriteriamacro",
            true,
            "Criterio de Evaluación Macro"
          ).toLowerCase()
        }}
        asociados a esta evaluación.
      </div>
    </template>
    <table class="evaluation-criterias-table">
      <thead class="evaluation-criterias-header">
        <th class="th-custom-first">
          {{
            $getVisibleNames(
              "teaching.evaluationcriteriamicro",
              true,
              "Criterio de Evaluación Micro"
            )
          }}
        </th>
        <th class="th-custom-second">Menciones</th>
      </thead>
      <tbody>
        <template v-for="item in filterECMicro">
          <tr
            class="ec-micro-tr"
            v-for="ec_micro in item.options"
            :key="ec_micro.id"
          >
            <td
              class="evaluation-criterias-micros"
              :class="{
                'criteria-without-mention': !appliedCriteriaMicroAmount(
                  ec_micro.id
                ),
              }"
            >
              <span class="evaluation-criterias-micros-order">
                {{ item ? item.order : "" }}.{{
                  ec_micro ? ec_micro.order : ""
                }}
              </span>
              <SentenceContainer
                class="text-class"
                :Sentence="ec_micro"
                :boundedTextContainer="true"
                :Large="140"
              ></SentenceContainer>
            </td>
            <td class="ec-micro-td text-center">
              <b-badge
                class="custom-badge"
                :class="{ 'bg-red': !appliedCriteriaMicroAmount(ec_micro.id) }"
                >{{ appliedCriteriaMicroAmount(ec_micro.id) }}</b-badge
              >
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <template v-if="!filterECMicro.length">
      <div class="text-center mb-3">
        <b-icon class="mr-1" icon="exclamation-circle"></b-icon> No existen
        {{
          $getVisibleNames(
            "teaching.evaluationcriteriamicro",
            true,
            "Criterio de Evaluación Micro"
          ).toLowerCase()
        }}
        asociados a esta evaluación.
      </div>
    </template>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "InstrumentsEvaluationCriterias",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    evaluation_id: { required: true },
    question_tests: { required: true },
    observation_instruments: { required: true },
  },
  data() {
    return { get_questions: [], get_dimensions: [] };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      section_evaluations: names.SECTION_EVALUATIONS,
      sections: names.SECTIONS,
      studyUnits: names.STUDY_UNITS,
      questions: names.QUESTIONS,
      rubricInstances: names.OBSERVATION_INSTANCES,
    }),

    sectionEvaluation() {
      return this.section_evaluations.find(
        (x) => x.evaluation == this.evaluation_id
      );
    },
    evaluationCriteriaMacroList() {
      if (!this.sectionEvaluation) return [];
      let list = this.evaluationCriteriasMacros
        .filter((x) =>
          this.sectionEvaluation.evaluation_criterias_macro.includes(x.id)
        )
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
      return list;
    },
    filterECMicro() {
      if (!this.sectionEvaluation) return [];
      let list = [];
      this.studyUnits.forEach((study_unit) => {
        const evaluation_criterias = this.evaluationCriteriasMicros.filter(
          (x) =>
            x.study_unit == study_unit.id &&
            this.sectionEvaluation.evaluation_criterias_micro.includes(x.id)
        );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: study_unit.name,
            order: study_unit.order,
            options: this.evaluationCriteriasMicros
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list;
    },
  },
  methods: {
    appliedCriteriaMacroAmount(criteria_id) {
      let count = 0;
      this.get_questions.forEach((question) => {
        if (question.evaluation_criterias_macro.includes(criteria_id)) count++;
      });
      this.get_dimensions.forEach((dimension) => {
        if (dimension.evaluation_criterias_macro.includes(criteria_id)) count++;
      });
      return count;
    },
    appliedCriteriaMicroAmount(criteria_id) {
      let count = 0;
      this.get_questions.forEach((question) => {
        if (question.evaluation_criterias_micro.includes(criteria_id)) count++;
      });
      this.get_dimensions.forEach((dimension) => {
        if (dimension.evaluation_criterias_micro.includes(criteria_id)) count++;
      });
      return count;
    },
    studyUnitOrder(study_unit_id) {
      const study_unit = this.studyUnits.filter((x) => x.id == study_unit_id);
      return study_unit ? study_unit.order : null;
    },
    filterQuestions() {
      this.question_tests.forEach((question_test) => {
        this.questions.forEach((question) => {
          if (question.question_test == question_test.id)
            this.get_questions.push(question);
        });
      });
    },
    filterDimensions() {
      this.observation_instruments.forEach((observation_instrument) => {
        this.rubricInstances.forEach((dimension) => {
          if (dimension.observation_instrument == observation_instrument.id)
            this.get_dimensions.push(dimension);
        });
      });
    },
  },
  created() {},
  mounted() {
    this.filterQuestions();
    this.filterDimensions();
  },
};
</script>
<style scoped>
.th-custom-first,
.th-custom-second {
  text-align: center;
}
.th-custom-first {
  width: 75%;
}
.th-custom-second {
  width: 25%;
}
.evaluation-criterias-table {
  width: 100%;
  margin-bottom: 0.8rem;
}
.evaluation-criterias-header {
  background-color: var(--primary-color);
}
.ec-macro-tr:nth-of-type(odd),
.ec-micro-tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.text-class {
  padding-top: 0.1rem;
}
.evaluation-criterias-macros-order,
.evaluation-criterias-micros-order {
  font-weight: 100;
}
.ec-macro-td {
  display: flex;
  text-align: left;
  padding: 0.4rem 0.2rem !important;
}
.criteria-without-mention {
  color: #dc3545 !important;
  font-weight: bold;
}
.evaluation-criterias-micros {
  display: flex;
  text-align: left;
  width: 100%;
  padding: 0.4rem 0.2rem !important;
}
.custom-badge {
  font-size: 11pt;
  padding: 0.4rem 0.6rem;
}
.bg-red {
  background-color: #dc3545 !important;
}
</style>
